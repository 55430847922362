import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from "mapbox-gl";
import ReactDOM from "react-dom";
import { ResizableBox } from 'react-resizable';
import {isMobile} from "react-device-detect";

import ColorPicker from "./ColorPicker";
import TextBoxEditor from "./Editor";

import {color_picker_data} from "../../../utils/color_picker_data";

import CloseIcon from '../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../assets/imgs/PaintBar/delete-trash-icon.svg';
import {ResizeIcon} from "../../../assets/imgs/PaintBar/resize";
import {RotateIcon} from "../../../assets/imgs/PaintBar/rotate";
import 'react-resizable/css/styles.css';
import "./style.css";

const PopUpTextBox = (props) => {
    const {
        markerDescription = '',
        markerColor = '#000000',
        markerBackground = '#FFFFFF',
        markerHeight = 95,
        markerWidth = isMobile ? 285 : 350,
        markerOpacity = 1,
        markerRotation = 0,
        markerFontSize = 14,
        handleDelete,
        id,
        handleSave,
        handleEnableDragging,
        handleStopDragging,
        markerRef,
        coordinates,
        map
    } = props
    const fontSizes = Array.from({ length: 14 }, (_, i) => 6 + i).filter(size => size % 2 === 1);
    const componentRef = useRef();
    const popupRef = useRef()
    const popupContainerRef = useRef()
    const editorRef = useRef();
    const textBoxRef = useRef();
    const clickTimeoutRef = useRef(null);
    const [text, setText] = useState(markerDescription);
    const [isEditing, setIsEditing] = useState(!markerDescription.length);
    const [fontSize, setFontSize] = useState(markerFontSize);
    const [backgroundColor, setBackgroundColor] = useState(markerBackground);
    const [textColor, setTextColor] = useState(markerColor);
    const [isOpenColors, setIsOpenColors] = useState(false);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [isOpenFontSizePicker, setIsOpenFontSizePicker] = useState(false);
    const [isFill, setIsFill] = useState(false);
    const [opacity, setOpacity] = useState(markerOpacity);
    const [rotation, setRotation] = useState(markerRotation);
    const [minConstraints, setMinConstraints] = useState([305, 95]);
    const [maxConstraints, setMaxConstraints] = useState([460, 440]);
    const [width, setWidth] = useState(markerWidth);
    const [height, setHeight] = useState(markerHeight);
    const [colorsForColorPicker, setColorsForColorPicker] = useState([
        { id: 1, color: "#000000", status: 1 },
        { id: 2, color: "#FFFFFF", status: 0 },
        { id: 3, color: "#53D669", status: 0 },
        { id: 4, color: "#FED031", status: 0 },
        { id: 5, color: "#FF6539", status: 0 },
        { id: 6, color: "#95D4F3", status: 0 },
        { id: 7, color: "#D8C9FE", status: 0 },
        { id: 8, status: 0 },
    ])

    const changeColorsColorPicker = (colorHEX) => {
        const updatedColors = colorsForColorPicker.map(color => {
            if (color.color === colorHEX) {
                return { ...color, status: 1 };
            } else {
                return { ...color, status: 0 };
            }
        });
        setColorsForColorPicker(updatedColors);
        isFill ? setBackgroundColor(colorHEX) : handleSetTextColor(colorHEX);
    };

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r},${g},${b}`;
    }

    const handleSaveWrapper = (text, finalRotation = rotation) => {
        handleSave(text, textColor, backgroundColor, opacity, height, width, finalRotation, fontSize);
    };

    const checkCharacterCount = (e) => {
        const unprivilegedEditor = editorRef.current.unprivilegedEditor;
        const currentLength = unprivilegedEditor.getLength();
        if (currentLength >= 500 && e.key !== 'Backspace') {
            e.preventDefault();
        }
    };

    const renderColorPopup = (node) => {
        ReactDOM.render (<ColorPicker
            isFill={isFill}
            setIsFill={setIsFill}
            setIsOpenColorPicker={setIsOpenColorPicker}
            opacity={opacity}
            handleRangeChange={handleRangeChange}
            colorsForColorPicker={colorsForColorPicker}
            colorSelectHandler={colorSelectHandler}
            backgroundColor={backgroundColor}
        />, node)
    }

    const renderColorPicker = () => {
        const toTop = isFill ? -145 : -120
        const popUpSettings = {
            closeButton: false,
            closeOnClick: true,
            dynamicPosition: true,
            anchor: 'bottom',
            offset: [0 , toTop]
        }
        popupContainerRef.current = document.createElement('div');
        popupContainerRef.current.id = id;
        popupRef.current = new mapboxgl.Popup(popUpSettings)
        renderColorPopup(popupContainerRef.current)
        popupRef.current.setLngLat(coordinates)
            .setDOMContent(popupContainerRef.current)
            .setMaxWidth(320)
        markerRef.current.setPopup(popupRef.current)
            .togglePopup();
    }

    const handleMouseDown = () => {
        clickTimeoutRef.current = new Date().getTime();
    };

    const handleMouseUp = (e) => {
        const clickDuration = new Date().getTime() - clickTimeoutRef.current;
        if (clickDuration < 200) {
            handleClick(e);
        }
    };
    const handleClose = () => {
        setIsOpenColors(false)
        setIsOpenColorPicker(false)
        setIsEditing(false)
        setIsOpenFontSizePicker(false)
        editorRef.current.blur()
    }

    const handleClick  = () => {
        map.fire('closeMapboxGlDrawPopupsAndSelection', {notChangeMode: true});
        const quillInstance = editorRef.current.getEditor();
        quillInstance.setSelection(quillInstance.getLength())
        if (isEditing) return
        setIsEditing(true)
    }

    const hasContent = (htmlString) => {
        const strippedString = htmlString.replace(/<[^>]*>/g, '').trim();
        return strippedString.length > 0;
    };

    const handleCloseWrapper = () => {
        const currentText = editorRef.current?.value?.trim();
        hasContent(currentText) ? handleClose() : handleDelete(id);
        map.doubleClickZoom.enable();
    };

    const toggleColorsDropdown = (e) => {
        map.doubleClickZoom.disable();
        e.stopPropagation();
        if (isOpenColorPicker && isOpenColors) {
            setIsOpenColorPicker (false);
        }
        setIsOpenColors(!isOpenColors);
    };

    const handleFontSizeChange = (option) => {
        setFontSize(option);
        const editorContainer = document.querySelector(`.quill-text-tool-${id} .ql-container.ql-snow .ql-editor`);
        if (editorContainer) {
            editorContainer.style.fontSize = `${option}px`
        }
        setIsOpenFontSizePicker(false)
        map.scrollZoom.enable()
    };

    const handleRotateMove = (e) => {
        const clientX = isMobile ? e.touches[0].clientX : e.clientX;
        const clientY = isMobile ? e.touches[0].clientY : e.clientY;
        const newRotation = calculateRotation(clientX, clientY);
        setRotation(newRotation - 20);
    };

    const calculateRotation = (clientX, clientY) => {
        const rect = textBoxRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const angle = Math.atan2(clientY - centerY, clientX - centerX) * (180 / Math.PI);
        return angle;
    };

    const handleEndRotate = (e) => {
        const finalRotation = calculateRotation(
            isMobile ? e.changedTouches[0].clientX : e.clientX,
            isMobile ? e.changedTouches[0].clientY : e.clientY
        ) - 20;

        if (e.type === 'mouseup') {
            document.removeEventListener('mousemove', handleRotateMove);
            document.removeEventListener('mouseup', handleEndRotate);
        } else if (e.type === 'touchend') {
            document.removeEventListener('touchmove', handleRotateMove);
            document.removeEventListener('touchend', handleEndRotate);
        }

        setTimeout(() => {
            handleSaveWrapper(text, finalRotation);
        }, 1000);
    };

    const handleStartRotate = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'mousedown') {
            document.addEventListener('mousemove', handleRotateMove);
            document.addEventListener('mouseup', handleEndRotate);
        } else if (e.type === 'touchstart') {
            document.addEventListener('touchmove', handleRotateMove);
            document.addEventListener('touchend', handleEndRotate);
        }
    };
    const handleOpenFontSizeChangePicker = (e) => {
        map.doubleClickZoom.disable();
        if(isOpenFontSizePicker) {
            map.scrollZoom.enable()
        } else {
            map.scrollZoom.disable();
        }
        setIsOpenFontSizePicker(!isOpenFontSizePicker)
    };

    const handleClickOutside = (e) => {
        const isClickInsideColorPickerPopup = e.target.closest('.box-color-and-opacity-picker-popup') ||
            e.target.closest(".ql-toolbar") ||
            e.target.closest(".popUp-actions")
        if (componentRef.current && !componentRef.current.contains(e.target) &&
            !isClickInsideColorPickerPopup &&
            e.target.className !== 'react-resizable-handle react-resizable-handle-se' &&
            e.target.className !== 'text_box_tools_color_item' &&
            e.target.className !== 'font-dropdown-content-span' &&
            e.target.className !== 'current-picked-color-comment-box') {
            handleCloseWrapper();
            editorRef.current.blur()
            // handleEnableDragging()
        }
    };

    const handleResize = (e, {size}) => {
        e.preventDefault()
        e.stopPropagation()
        setHeight(size.height)
        setWidth(size.width)
        const height = editorRef.current.editingArea.clientHeight + 15
        const minWidth = isMobile ? 265 : fontSize >= 16 ? 365 : 305
        setMinConstraints([minWidth, height > 95 ? height : 95])
        setMaxConstraints([460, height > 440 ? height : 440])
        setIsOpenColors(false)
    }

    const handleRangeChange = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpacity(e.target.value / 100);
    };

    const svgToDataURL = (svg) => {
        const base64 = window.btoa(svg);
        return `data:image/svg+xml;base64,${base64}`;
    };

    const changeCursorsRotation = () => {
        const resizeElements = document.querySelectorAll('.react-resizable-handle-se');
        const rotateElements = document.querySelectorAll('.rotate-handle');
        if (resizeElements.length > 0 || rotateElements.length > 0) {
            const resizeCursor = svgToDataURL(ResizeIcon(rotation));
            const rotateCursor = svgToDataURL(RotateIcon(rotation));
            resizeElements.forEach(handleElement => {
                handleElement.style.cursor = `url(${resizeCursor}), auto`;
            });
            rotateElements.forEach(handleElement => {
                handleElement.style.cursor = `url(${rotateCursor}), auto`;
            });

        }
    };

    const handleStopResizeRotate = () => {
        handleEnableDragging()
        handleSaveWrapper(text)
        changeCursorsRotation()
    }

    const handleSetTextColor = (color) => {
        setTextColor(color);
        const editorContainer = document.querySelector(`.quill-text-tool-${id} .ql-container.ql-snow .ql-editor`);
        if (editorContainer) {
            editorContainer.style.color = color;
            const span = editorContainer.querySelector('span');
            if (span) span.style.color = color;
        }
    };
    const colorSelectHandler = (e, selectedColor, isFill) => {
        e.preventDefault();
        e.stopPropagation();
        // handleStopDragging();
        map.doubleClickZoom.disable();
        if (selectedColor.id === colorsForColorPicker.length) {
            setIsOpenColorPicker(!isOpenColorPicker);
            return;
        }
        isFill ? setBackgroundColor(selectedColor.color) : handleSetTextColor(selectedColor.color);
        changeColorsColorPicker(selectedColor.color);
    };

    useEffect(() => {
        if (!isEditing) return;
        document.addEventListener ('mousedown', handleClickOutside);
        document.addEventListener ('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener ('mousedown', handleClickOutside);
            document.removeEventListener ('touchstart', handleClickOutside);
        };
    }, [isEditing]);

    useEffect(() => {
        handleSetTextColor(markerColor)
        handleFontSizeChange(markerFontSize)
        changeCursorsRotation()
    },[])

    useEffect(() => {
        if(editorRef.current.editingArea.clientHeight >= height || editorRef.current.editingArea.clientHeight >= 95) {
            const height = editorRef.current.editingArea.clientHeight + 15
            markerHeight < height ? setHeight(height) : setHeight(markerHeight)
        }
    }, [text, fontSize])

    useEffect(() => {
        handleSaveWrapper(text)
    },[text, textColor, backgroundColor, opacity, fontSize])

    useEffect(() => {
        isOpenColors ? renderColorPicker() : markerRef?.current?.togglePopup();
    },[isOpenColors, isFill, backgroundColor, textColor])

    useEffect(() => {
        const handleTouchStart = (e) => {
            e.preventDefault();
            e.stopPropagation();
            map.doubleClickZoom.disable();
        };

        const touchItems = document.querySelectorAll(".text_box_tools_color_item");
        touchItems.forEach(item => {
            item.addEventListener("touchstart", handleTouchStart, { passive: false });
        });
        return () => {
            touchItems.forEach(item => {
                item.removeEventListener("touchstart", handleTouchStart);
            });
        };
    }, []);

    return (
        <div
            className='sticky_note_container'
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            style={{
                width: width,
                height: height,
            }}
            ref={textBoxRef}>
            <ResizableBox
                onMouseEnter={changeCursorsRotation}
                style={{transform: `rotate(${(isOpenColors || isOpenFontSizePicker)  ? 0 : rotation}deg)`, cursor: ResizeIcon}}
                width={width} height={height} minConstraints={minConstraints}
                maxConstraints={maxConstraints}
                className={'textBox'}
                onResize={handleResize}
                onResizeStart={handleStopDragging}
                onResizeStop={handleStopResizeRotate}
            >
                    <div className={'textBox-container'} ref={componentRef}>
                        {isOpenColorPicker && (
                            <div
                                className="text_box_tools_color_item_container"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                {color_picker_data.map((color, i) => (
                                    <div
                                        key={i}
                                        className="text_box_tools_color_item"
                                        style={{ backgroundColor: color }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            map.doubleClickZoom.disable();
                                            changeColorsColorPicker(color);
                                            setIsOpenColorPicker(false);
                                        }}
                                        onTouchStart={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            map.doubleClickZoom.disable();
                                            changeColorsColorPicker(color);
                                            setIsOpenColorPicker(false)
                                        }}
                                    ></div>
                                ))}
                            </div>
                        )}
                        {isOpenFontSizePicker && (
                            <div
                                className="font-dropdown-content"
                                onTouchStart={(e) => {
                                    e.stopPropagation();
                                }}
                                onTouchEnd={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {fontSizes.map((index) => {
                                    const option = index + 1;
                                    return (
                                        <span
                                            onTouchStart={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                map.doubleClickZoom.disable();
                                                // handleFontSizeChange(option)
                                            }}
                                            key={option}
                                            className={`font-dropdown-content-span ${option === fontSize ? 'selected' : ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                map.doubleClickZoom.disable();
                                                // handleFontSizeChange(option)
                                            }}
                                        >
                                            {option}
                                        </span>
                                    );
                                })}
                            </div>
                        )}
                        {isEditing && <div className={'edit-popup-header'}>
                            <div className={'popUp-actions'}>
                                <div className={'color-picker-wrapper'}>
                                    <div className={'color-picker-comment-box'}>
                                        <div className='comment-box-popup-container_color-picker'
                                             onClick={toggleColorsDropdown}
                                             onTouchStart={toggleColorsDropdown}>
                                        <span className={"current-picked-color-comment-box"}>
                                            <svg
                                                style={{stroke: '#000000'}}
                                                strokeWidth="3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill={textColor}
                                            >
                                                <circle
                                                    cx="14"
                                                    cy="14"
                                                    r="11.5"
                                                    stroke={'#fff'}
                                                    strokeWidth="2"
                                                />
                                                {/* MAYBE BE NEEDED */}

                                                {/*<circle*/}
                                                {/*    cx="14"*/}
                                                {/*    cy="14"*/}
                                                {/*    r="10.5"*/}
                                                {/*    stroke={commentColor}*/}
                                                {/*/>*/}
                                                {/*<circle*/}
                                                {/*    cx="14"*/}
                                                {/*    cy="14"*/}
                                                {/*    r="9"*/}
                                                {/*    fill={commentColor}*/}
                                                {/*/>*/}

                                            </svg>
                                        </span>
                                        </div>
                                    </div>
                                    <div className='font-size-picker ' onTouchStart={handleOpenFontSizeChangePicker} onClick={handleOpenFontSizeChangePicker}>
                                        {fontSize}
                                    </div>
                                </div>
                                <span className={'popUp-delete'} onClick={() => handleDelete (id)} onTouchStart={() => handleDelete (id)}>
                                <img src={DeleteIcon} alt=""/>
                            </span>
                                <span className={'popUp-close'} onClick={handleCloseWrapper} onTouchEnd={handleCloseWrapper}>
                                <img src={CloseIcon} alt=""/>
                            </span>
                            </div>
                        </div>}
                        <div  onMouseDown={handleMouseDown}
                              onTouchStart={handleMouseDown}
                              onMouseUp={handleMouseUp}
                              onTouchEnd={handleMouseUp}
                              style={{background: `rgba(${hexToRgb(backgroundColor)}, ${opacity})`}}
                              className={`popUp-editor popUp-editor-${isEditing}`}>
                            <TextBoxEditor
                                markerRef={markerRef}
                                id={id}
                                checkCharacterCount={checkCharacterCount}
                                editorRef={editorRef}
                                text={text}
                                setText={setText}
                                isEditing={isEditing}
                            />
                        </div>
                </div>
                <div className='rotate-handle' onMouseDown={handleStartRotate} onTouchStart={handleStartRotate} />
            </ResizableBox>
        </div>
    )
};

export default PopUpTextBox;
